<template>
    <div>
        <van-nav-bar
            title="方案说明"
            left-arrow
            @click-left="onClickLeft"
        />
  
      <img src="../../../../assets/image/powerMeasure/result_b_explain.png" alt="">
      <button class="toApply" @click="toApply">去申请</button>
    </div>
</template>
<script>


    export default{
        name:'PlanExplain',
        data(){
            return{
             
            }
        },
        methods:{
            onClickLeft(){
                this.$router.back();
            },
            toApply(){
                this.$router.push({
                    path: "electricityBplan",
                    query:{
                        id:this.$route.query.id,
                     }
                })
            }
        },
    
       
    }
</script>
<style scoped>
img{
    width: 100%;
}
.toApply{
    position: absolute;
    bottom: 80px;
    left:6%;
    z-index: 9999;
    width: 88%;
    background: linear-gradient(-20deg, #E78505 0%, #FECA3D 100%);
    box-shadow: 0px -5px 6px 0px rgba(111,60,5,0.65);
    border-radius: 45px;
    display: block;
    font-size: 48px;
    font-family: PingFang SC;
    font-weight: 600;
    color: #FFFFFE;
    line-height: 90px;
    text-shadow: 2px 3px 0px rgba(206,123,8,0.93);
    text-align: center;
    margin: 0 auto;
}
</style>
